import {AfterViewInit, Component, ElementRef, Input, OnChanges,  SimpleChanges, ViewChild} from '@angular/core';
import SignaturePad from 'signature_pad';
import {IPointGroup, SignaturePadOptions} from './models/signaturePadOptions';

@Component({
  selector: 'ngx-signature-pad',
  template: `
    <div #signaturePadContainer>
      <canvas #signaturePad></canvas>
    </div>
  `,
})
export class NgxSignaturePadComponent implements OnChanges, AfterViewInit {

  @Input() config: SignaturePadOptions;
  @ViewChild('signaturePad', {static: false}) signaturePadElement: ElementRef;
  @ViewChild('signaturePadContainer', {static: false}) signaturePadContainerElement: ElementRef;

  private signaturePad: SignaturePad;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.config.firstChange) {
      Object.keys(changes.config.currentValue).map(key => {
        this.signaturePad[key] = changes.config.currentValue[key];
      });
    }
  }

  ngAfterViewInit(): void {
    this.setCanvasSize();
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement, this.config);
  }


  private setCanvasSize(): void {
    const {offsetWidth, offsetHeight} = this.signaturePadContainerElement.nativeElement;
    this.signaturePadElement.nativeElement.width = offsetWidth;
    this.signaturePadElement.nativeElement.height = offsetHeight;
  }


  clear(): void {
    this.signaturePad.clear();
  }

  fromDataURL(dataUrl: string, options?: {
    ratio?: number;
    width?: number;
    height?: number;
  },          callback?: (error?: ErrorEvent) => void): void {
    this.signaturePad.fromDataURL(dataUrl, options, callback);
  }


  toDataURL(type?: string, encoderOptions?: number): string {
    return this.signaturePad.toDataURL(type, encoderOptions);
  }

  on(): void {
    this.signaturePad.on();
  }

  off(): void {
    this.signaturePad.off();
  }

  isEmpty(): boolean {
    return this.signaturePad ? this.signaturePad.isEmpty() : true;
  }

  fromData(pointGroups: IPointGroup[]): void {
    this.signaturePad.fromData(pointGroups);
  }

  toData(): IPointGroup[] {
    return this.signaturePad.toData();
  }

  forceUpdate(): void {
    this.setCanvasSize();
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement, this.config);
  }

}
