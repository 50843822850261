import { NgModule } from '@angular/core';
import { SignaturePad } from './signature-pad';

@NgModule({
  imports: [ ],
  declarations: [ SignaturePad ],
  exports: [ SignaturePad ],
})

export class SignaturePadModule { }
