import { NgModule } from '@angular/core';
import { NgxSignaturePadComponent } from './ngx-signature-pad.component';



@NgModule({
  declarations: [NgxSignaturePadComponent],
  imports: [
  ],
  exports: [NgxSignaturePadComponent]
})
export class NgxSignaturePadModule { }
